import Swal from "sweetalert2";
import { Button } from "reactstrap";
import axios from "axios";
import { isMobile, isTablet } from 'react-device-detect';
export const RBButton = Button;

const BaseURL =
  //"http://64.227.131.3:8443/v1/";
   //"http://192.168.0.233:8443/v1/";
         //"http://localhost:8443/v1/";
    "https://api.whosin.me/v1/";
 //"http://192.168.0.233:8443/v1/";



// const fetchDeviceInfo = async () => {
//   try {
//     const response = await fetch('https://api.ipify.org?format=json');
//     const data = await response.json();
//     let ipAddress = data.ip
//     const browserName = navigator.userAgent;
//     let deviceType;
//     if (isMobile) {
//       deviceType = 'Mobile';
//     } else if (isTablet) {
//       deviceType = 'Tablet';
//     } else {
//       deviceType = 'Desktop';
//     }
//     const deviceInfo = {
//       ipAddress,
//       browserName,
//       deviceType,
//       // You can add more device information here if needed
//     };
//     return deviceInfo
   
//   } catch (error) {
//     console.error('Error fetching IP address:', error);
//   }
// };


const fetchDeviceInfo = async () => {
  try {
    const apiList = [
      "https://ipinfo.io/json",
      "https://ipapi.co/json",
      "https://api64.ipify.org?format=json",
      "https://api.ipify.org?format=json"
    ];

    let data;
    for (const api of apiList) {
      try {
        const response = await fetch(api);
        if (response.ok) {
          data = await response.json();
          break;
        }
      } catch (error) {
        console.warn(`Error with API ${api}, trying next...`, error);
      }
    }

    if (!data) throw new Error('All IP APIs failed');

    const ipAddress = data.ip || data.ipAddress || 'IP not available';
    const browserName = navigator.userAgent;
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const isTablet = /Tablet|iPad/i.test(navigator.userAgent);
    const deviceType = isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop';

    const deviceInfo = {
      ipAddress,
      browserName,
      deviceType,
    };

    return deviceInfo;
  } catch (error) {
    console.error('Error fetching device information:', error);
    return null;
  }
};

const getheader = async () => {
  let deviceInfo = await fetchDeviceInfo();
  let token = await localStorage.getItem("token");
  let header = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    'x-device-ip': deviceInfo.ipAddress,
    'x-device-browserName': deviceInfo.browserName,
    'x-device-type': deviceInfo.deviceType,
  };
  return header;
};

const getheaderForm = async () => {
  let deviceInfo = await fetchDeviceInfo();
  let token = await localStorage.getItem("token");
  let header = {
    Authorization: `Bearer ${token}`,
    'X-Device-Ip': deviceInfo.ipAddress,
    'X-Device-BrowserName': deviceInfo.browserName,
    'X-Device-Type': deviceInfo.deviceType,
  };
  return header;
};

export const postRequestForm = async (endpoint, body) => {
  let hearder = await getheaderForm();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "POST", headers: hearder, body: body })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const callCSVRequest = async (endpoint, body) => {
  let hearder = await getheaderForm();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "POST", headers: hearder, body: body })
      .then((response) => response.blob())
      .then((blob) => resolve(blob));
  });
  return promise;
}

export const putRequestForm = async (endpoint, body) => {
  let hearder = await getheaderForm();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "PUT", headers: hearder, body: body })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const postCustomRequestForm = async (endpoint, body) => {
  let hearder = await getheaderForm();
  let url = BaseURL2 + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "POST", headers: hearder, body: body })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const pushUpdates = async (body) => {
  try {
    let header = await getheader();
    let url = BaseURL + "chat/event/update";
    const response = await fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in POST request:", error);
    throw error;
  }
};

// export const postRequest = async (endpoint, body) => {
//   let header = await getheader();
//   let url = BaseURL + endpoint;
//   const promise = new Promise(async (resolve, reject ) => {
//     await fetch(url, {
//       method: "POST",
//       headers: header,
//       body: JSON.stringify(body),
//     })
//       .then((response) => response.json())
//       .then((data) => resolve(data))
//       .catch((error) => {
//         reject(error);
//       }
//   });
//   return promise;
// };

export const postRequest = async (endpoint, body) => {
  try {
    let header = await getheader();
    let url = BaseURL + endpoint;
    const response = await fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in POST request:", error);
    throw error;
  }
};

export const getRequest = async (endpoint, body = null) => {
  let header = await getheader();
  let url = BaseURL + endpoint;

  if (body != null) {
    const promise = new Promise(async (resolve) => {
      await fetch(url, {
        method: "Get",
        headers: header,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
    return promise;
  }
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "Get", headers: header })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const deleteConfirmationWords = ["Delete", "Remove", "Erase"];

export const deleteRequest = async (endpoint, body) => {
  let header = await getheader();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, {
      method: "DELETE",
      headers: header,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const putRequest = async (endpoint, body) => {
  let header = await getheader();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, {
      method: "PUT",
      headers: header,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export function DeleteConfirmDialog() {
  const promise = new Promise(async (resolve, reject) => {
    try {
      Swal.fire({
        title: "Are you sure delete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        resolve(result);
      });
    } catch (msg) {
      reject(msg);
    }
  });
  return promise;
}

export function convertTimeToFormattedString(timestampString) {
  if (!timestampString) {
    return timestampString;
  }
  timestampString = timestampString.replace("Z", "");
  // Convert to Date object
  const dateObj = new Date(timestampString);
  // Check if the date object is valid
  if (isNaN(dateObj.getTime())) {
    return timestampString;
  }
  // Extract date, month, year, hours, and minutes
  const date = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = dateObj.getFullYear();
  let hours = dateObj.getHours();
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";
  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // Construct formatted string
  return `${date}/${month}/${year} ${hours}:${minutes} ${ampm}`;
}

// Validator utility
const validateEmail = (email) => {
  // Standard Email Validation RegExp
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const validatePhone = (phone) => {
  var re = /^[0-9\b]+$/; // checks for numeric value
  return re.test(phone);
};

const validateDate = (date) => {
  return !isNaN(Date.parse(date));
};

const validateDateTime = (dateTime) => {
  const date = new Date(dateTime);
  return !isNaN(date.getTime()) && date.toISOString() === dateTime;
};

export const customValidator = (validaterObj, valueObj) => {
  try {
    for (let i = 0; i < validaterObj.length; i++) {
      const {
        field,
        required = true,
        type = "any",
        errorMsg,
      } = validaterObj[i];

      // Check if field is required and missing
      if (required && !valueObj[field]) {
        return { success: false, message: errorMsg || `${field} is required` };
      }

      // Field value
      const fieldValue = valueObj[field];
      // Type validation
      switch (type) {
        case "email":
          if (!validateEmail(fieldValue)) {
            return {
              success: false,
              message: errorMsg || "Invalid email format",
            };
          }
          break;
        case "phone":
          if (!validatePhone(fieldValue)) {
            return {
              success: false,
              message: errorMsg || "Invalid phone number format",
            };
          }
          break;
        case "not-null":
          if (!fieldValue) {
            return {
              success: false,
              message: errorMsg || "Invalid file format",
            };
          }
          break;
        case "date":
          if (!validateDate(fieldValue)) {
            return {
              success: false,
              message: errorMsg || "Invalid date format",
            };
          }
          break;
        case "date-time":
          if (!validateDateTime(fieldValue)) {
            return {
              success: false,
              message: errorMsg || "Invalid datetime format",
            };
          }
          break;
        case "string":
          if (typeof fieldValue !== "string") {
            return {
              success: false,
              message: errorMsg || `${field} must be a string`,
            };
          }
          break;
        case "number":
          if (typeof fieldValue !== "number") {
            return {
              success: false,
              message: errorMsg || `${field} must be a number`,
            };
          }
          break;
        case "boolean":
          if (typeof fieldValue !== "boolean") {
            return {
              success: false,
              message: errorMsg || `${field} must be a boolean`,
            };
          }
          break;
        case "array":
          if (!Array.isArray(fieldValue)) {
            return {
              success: false,
              message: errorMsg || `${field} must be an array`,
            };
          }
          break;
        case "object":
          if (typeof fieldValue !== "object") {
            return {
              success: false,
              message: errorMsg || `${field} must be an object`,
            };
          }
          break;
        // add other type validations as needed
      }
    }

    // If everything is good
    return { success: true };
  } catch (error) {
    return { success: false, message: "Something went wrong" };
  }
};
