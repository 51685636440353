import React , {useEffect} from "react"

import { CountUp } from 'countup.js';

import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Button
} from "reactstrap"

import { 
  postRequest
  
} from "components/Common/Utils";

// Pages Components
// import Miniwidget from "./Miniwidget"
// import MonthlyEarnings from "./montly-earnings";
// import EmailSent from "./email-sent";
// import MonthlyEarnings2 from "./montly-earnings2";
// import Inbox from "./inbox";
// import RecentActivity from "./recent-activity";
// import WidgetUser from "./widget-user";
// import YearlySales from "./yearly-sales";
// import LatestTransactions from "./latest-transactions";
// import LatestOrders from "./latest-orders";
// import { useHistory, useParams, useLocation } from "react-router-dom";
// import ChartComponets from "./ChartData.js";
// import MiniWidgets from "./MiniWidgets";
// import RevenueAnalytics from "./RevenueAnalytics";
// import SalesAnalytics from "./SalesAnalytics";
// import EarningReports from "./EarningReports";
// import Sources from "./Sources";
// import RecentlyActivity from "./RecentlyActivity";
// import RevenueByLocations from "./RevenueByLocations";
// import OnliUserByLoctions from "./OnliUserByLoctions";

import DashCard from "./DashCard";
// import Calendar from "./Calendar";


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const temporaryData = {
  currentMonth: [
    { date: "2024-05-01", value: 100 },
    { date: "2024-05-02", value: 150 },
    { date: "2024-05-03", value: 200 },
    // Add more daily data as needed
  ],
  prevMonth: [
    { date: "2024-04-01", value: 80 },
    { date: "2024-04-02", value: 120 },
    { date: "2024-04-03", value: 160 },
    // Add more daily data as needed
  ],
  currentYear: [
    { month: "January", value: 2000 },
    { month: "February", value: 2500 },
    { month: "March", value: 3000 },
    // Add more monthly data as needed
  ],
  prevYear: [
    { month: "January", value: 1800 },
    { month: "February", value: 2200 },
    { month: "March", value: 2700 },
    // Add more monthly data as needed
  ],
  thisMonthTotal: 4500,
  prevMonthTotal: 3600,
  thisYearTotal: 30000,
  prevYearTotal: 29000
};



const Dashboard = (props) => {
   

    const [dashCardStat, setDashCardStat] = React.useState([]);

    // const [salesData, setSalesData] = React.useState([]);

    // const [countryWiseData, setCountryWiseData] = React.useState([]);

    // const [ calendarEvents, setCalendarEvents ] = React.useState([]);

    // const [pieChartData, setPieChartData] = React.useState({});

    // const [claimedAmount, setClaimedAmount] = React.useState(null);

    // const [analyticsData, setAnalyticsData] = React.useState(null);  

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
  },)

  



  return (
    <React.Fragment>

    
<Row>
<Col className='12'>
            {/* <div className="page-title-box">
              <h4>Dashboard </h4>
             
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Dashboard</li>
                : <li className="breadcrumb-item">Dashboard Counter</li>
              </ol>
            </div> */}
          </Col>

          {/* <div className="col-md-2 text-end">
            <Link to="/graph">
              {" "}
              <Button>
                
                {" "}
                <i class="fal fa-chart-lisne fs-5 text-light"></i> View Graph
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div> */}


<Row>

  <DashCard values={props.widgetData} />       


</Row>

  

    
                        
                
        


</Row>

      

    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);