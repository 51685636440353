import React, { useState, useEffect } from 'react';
import { getRequest, postRequest } from "../Common/Utils.js";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, NavLink } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import { Row, Col, Card, CardBody, CardTitle, Spinner, Badge, UncontrolledTooltip, Modal, Button } from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

const TableComponent = ({ selectRow }) => {
  const [venuedata, setvenuedata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("createdAt");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [searchText, setSearchText] = useState('');


  useEffect(() => {
    venueManagment();
  }, [currentPage])

  useEffect(() => {
    venueManagment();
  }, [sizePerPageOrg, sort]);




  const handleTableChange = (type, { page, sizePerPage, searchText }) => {

    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }
    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];



  function BusinessFormatter(cellContent, row) {
    return (
      <div style={{ display: 'flex' }}>
        <p>
          {row?.business_id?.name}
        </p>
      </div>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return (
      <>{i + 1}</>
    );
  }
  function ImgFormatter(cellContent, row) {


    return (
      <>
        <img
          src={row.thumbImage}
          onClick={() => {
            setImages(row.imageFiles); // Set all images
            showLightBox(row.imageFiles[0]); // Open the lightbox with current image
          }}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }




  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "tourName",
      text: "Tour Name",
      sort: false,
    },

    {
      dataField: "countryName",
      text: "Country",
      sort: false,
    },
    {
      dataField: "cityName",
      text: "City",
      sort: false,
    },
    // {
    //   dataField: "mediumImage",
    //   text: "Image",
    //   formatter: ImgFormatter,
    //   sort: false,
    // },
    {
      dataField: "cityTourType",
      text: "Tour Type",
      sort: false,
    },



    {
      dataField: "select",
      text: "Select",
      formatter: iconFormatter,
      sort: false,
    },
  ];


  const handleChange = (event) => {

    console.log("page value ", event.target.value);
    setSizePerPage(event.target.value);
  }
  const handlesorting = (event) => {
    setsort(event.target.value);
    console.log("sorting  value ", event.target.value);
  }
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className='d-flex'>
          <button type='button' className="btn btn-danger" onClick={() => selectRow(row, row._id, row.name)}><i class="fal fa-plus  fs-5"></i></button>
        </div>
      </React.Fragment>
    );
  }


  const limit = 10;

  const venueManagment = async (page = 0) => {
    console.log(currentPage);
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: "asc",
    };

    if (searchText) {
      payload.search = searchText;
    }

    const response = await postRequest(`rayna/tour-list-admin`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setvenuedata(data.list);
    setLoader(false);
  }

  const resetSearchData = async () => {
    setSearchText("")
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: "asc",
    };
    const response = await postRequest(`rayna/tour-list-admin`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setvenuedata(data.list);
    setLoader(false);
  }





  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              {loader ?
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position: "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :
                <CardBody className="table-shorting">
                  {

                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (<>
                        <ToolkitProvider
                          keyField="_id"
                          data={venuedata}
                          columns={columns}
                        >
                          {
                            props =>
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <div className='w-50 d-flex'>
                                    <label className="mb-0 text-label">
                                      Page Size
                                    </label>

                                    <select
                                      value={sizePerPageOrg}
                                      onChange={handleChange}
                                      className="form-select"
                                      style={{ width: "10%" }}
                                    >
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                    </select>
                                  </div>
                                  <div className='w-50 text-end'>
                                    <div className='d-flex g-3 justify-content-end'>
                                      <input type="search" placeholder='Search' value={searchText} class="form-control" onChange={(e) => setSearchText(e.target.value)} style={{ width: "30%" }} />
                                      <button onClick={() => { venueManagment() }} type='button' className='btn btn-primary mx-2'>Search</button>
                                      <button
                                        onClick={() => {
                                          resetSearchData();
                                        }}
                                        type="button"
                                        className="btn btn-danger w-md mx-1"
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </div>


                                </Col>


                                <Col className='col-lg-12 text-end'>
                                  <BootstrapTable onTableChange={handleTableChange}
                                    remote={true} classes='table-striped'  {...props.baseProps} {...paginationTableProps} />
                                </Col>

                              </Row>
                          }
                        </ToolkitProvider>

                        <Row>
                          <Col className='col-lg-12 text-end'>

                            <div className="text-md-right">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>

                        </Row>
                      </>
                      )}
                    </PaginationProvider>


                  }


                </CardBody>
              }
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};



const RaynaTicketModal = ({ show, closeModal, selectRow }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          id="myLargeModalLabel"
        >
          Rayna Tour List
        </h5>

        <button
          onClick={() => {
            closeModal()
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div className="modal-body">
        <TableComponent selectRow={selectRow} />
      </div>

    </Modal>
  );
};

export default RaynaTicketModal;
