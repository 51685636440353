import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  putRequest,
} from "../../components/Common/Utils.js";
// import CustomLoder from "pages/TicketCategory/CustomLoder.js";
// import MultiselectCommon from "./MultiselectCommon.js";
// import TicketModal from "components/Modal/TicketModal.js";
// import HeliDubaiTicketModal from "components/Modal/HeliDubaiTicketModal.js";
// import DubaiParkTicketModal from "components/Modal/DubaiParkTicketModal.js";
// import EmaarTicketModal from "components/Modal/EmaarTicketModal.js";
import RaynaTicketModal from "components/Modal/RaynaTicketModal.js";
// import BigbusTicketModal from "components/Modal/BigbusTicketModal.js";
// import CustomTicketModal from "components/Modal/CustomTicketModal.js";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import IconPackModal from "components/Modal/IconPackModal.js";
import IconPackModalupdated from "components/Modal/IconPackModalupdated.js";
export default function UpdatedCustomTicket() {
  const { id } = useParams();
  const [previewImage, setPreviewImage] = useState([]);
  const [ShowLoder, setShowLoder] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedCaterogy, setselectedCaterogy] = useState("");
  const [selectedTicketoption, setselectedTicketoption] = useState([]);
  const [url, seturl] = useState([]);
  const location = useLocation();
  const state = location.state?.row;
  const [bannerData, setBannerData] = useState([]);
  const [categoryOptions, setCategoryData] = useState([]);
  const [subCategoryOptions, setSubCategoryData] = useState([]);
  const [categoryDataIds, setSelectedCaterogyIds] = useState([]);
  const [subCategoryDataIds, setSelectedSubCaterogyIds] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedSubCategory, setSelectedSubCaterogy] = useState([]);
  const [defaultcategory, setDefaultCategory] = useState([]);
  const [defaultsubcategory, setDefaultSubCategory] = useState([]);

  const [packages, setPackages] = useState([]);
  const [WhatsInclude, setIncludePackages] = useState([]);
  const [iconModal, setIconModal] = useState(false);
  const [iconModalInclude, seticonModalInclude] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedIconInclude, setselectedIconInclude] = useState("");

  

  let history = useHistory();
  // useEffect(() => {
  //   CategorylistManagment();
  // }, [selectedOptions]);
  // useEffect(() => {
  //   SubCategorylistManagment();
  // }, [selectedSubCategory]);

  const selectIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const selectIconRowIWhatsnclude = (icon) => {
    const packagesList = [...WhatsInclude];
    packagesList[selectedIconInclude].icon = icon.image;
    packagesList[selectedIconInclude].iconName = icon.title;
    setIncludePackages(packagesList);
    closeIconModalInclude();
  };

  const selectIncludeIconRowWhats = (icon) => {
    const packagesList = [...WhatsInclude];
    packagesList[selectedIconInclude].icon = icon.image;
    packagesList[selectedIconInclude].iconName = icon.title;
    setIncludePackages(packagesList);
    closeIconModalInclude();
  };

  const selectIncludeIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const closeIconModal = () => {
    setIconModal(false);
  };
  const closeIconModalInclude = () => {
    seticonModalInclude(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const openIconModalInclude = (index) => {
    setselectedIconInclude(index);
    seticonModalInclude(true);
  };

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };
  const updatePackageFieldInclude = (index, fieldName, value) => {
    const newPackages = [...WhatsInclude];
    newPackages[index][fieldName] = value;
    setIncludePackages(newPackages);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);

    setPackages(newPackages);
  };

  const deleteIncludePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);

    setIncludePackages(newPackages);
  };

  const addRow = () => {
    setPackages([...packages, { icon: "", feature: "", iconName: "" }]);
  };

  const addIncludeRow = () => {
    setIncludePackages([
      ...WhatsInclude,
      { icon: "", feature: "", iconName: "" },
    ]);
  };

  const saveIncludePackage = (index) => {
    const newPackages = [...WhatsInclude];
    newPackages[index] = { ...newPackages[index], saved: true };
    setIncludePackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const UpdateCustomTicketManagment = async () => {
    try {
      const data = state;
      setBannerData(data);
      setSelectedOptions(data?.categoryIds);
      setSelectedSubCaterogy(data?.subCategoryIds);
      setselectedTicketoption(data?.ticketOptions);
      seturl(data?.images);
      setSelectedCaterogyIds(data?.categoryIds);
      setSelectedSubCaterogyIds(data?.subCategoryIds);
      setPreviewImage(data?.images);
      setPackages(data?.features || []);
      setIncludePackages(data?.whatsInclude || []);
      setShowLoder(false);
      setActivityText(data?.code)
      setRaynaText(data?.tourData[0]?.tourName)

      return;


      const response = await postRequest(`rayna/custom/list`, {
        customTicketId: id,
      });
      //const data = response.data?.list?.[0];
      console.log( "======>>>>>",{ data });
      if (data) {
        setBannerData(data);
        setSelectedOptions(data?.categoryIds);
        setSelectedSubCaterogy(data?.subCategoryIds);
        setselectedTicketoption(data?.ticketOptions);
        seturl(data?.images);
        setSelectedCaterogyIds(data?.categoryIds);
        setSelectedSubCaterogyIds(data?.subCategoryIds);
        setPreviewImage(data?.images);
        setPackages(data?.features || []);
        setIncludePackages(data?.whatsInclude || []);
        setShowLoder(false);
        setActivityText(data?.code)
        console.log("--->dataname",data?.serviceContentInfo?.ServiceName);
      }
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
    }
  };

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCaterogyIds(ids);
  };

  const msUpdateSubCat = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedSubCaterogyIds(ids);
  };

  // useEffect(() => {
  //   SubCategorylistManagment();
  // }, [categoryDataIds]);
  // const CategorylistManagment = async () => {
  //   const payload = {
  //     limit: 100000,
  //     page: 1,
  //   };
  //   try {
  //     const response = await postRequest(`ticket/category/list`, payload);
  //     const data = response.data;
  //     let filter = data.list.map((item) => ({
  //       name: item?.name,
  //       id: item?._id,
  //     }));
  //     setCategoryData(filter);
  //     // console.log({ filter });
  //     if (selectedOptions.length > 0) {
  //       const defaultItems = filter.filter((item) => {
  //         return selectedOptions.includes(item.id);
  //       });
  //       console.log(defaultItems);
  //       setDefaultCategory(defaultItems);
  //     }
  //   } catch (error) {
  //     console.log("Failed to fetch data:", error);
  //   } finally {
  //   }
  // };

  // const SubCategorylistManagment = async () => {
  //   const payload = {
  //     limit: 100000,
  //     page: 1,
  //     categoryIds: categoryDataIds,
  //   };
  //   try {
  //     const response = await postRequest(
  //       `ticket/sub-category/by-category-ids`,
  //       payload
  //     );
  //     const data = response.data;
  //     let filter = data.map((item) => ({
  //       name: item?.name,
  //       id: item?._id,
  //     }));
  //     setSubCategoryData(filter);
  //     if (selectedSubCategory.length > 0) {
  //       const defaultItems = filter.filter((item) => {
  //         return selectedSubCategory.includes(item.id);
  //       });
  //       console.log({ defaultItems });
  //       setDefaultSubCategory(defaultItems);
  //     }
  //   } catch (error) {
  //     console.log("Failed to fetch data:", error);
  //   } finally {
  //   }
  // };

  // useEffect(() => {
  //   SubCategorylistManagment();
  // }, [categoryDataIds]);

  useEffect(() => {
    // CategorylistManagment();
    // SubCategorylistManagment();

    console.log("fetch-state", state);

    UpdateCustomTicketManagment();
  }, []);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      // setPreviewImage(reader.result);
      setPreviewImage((oldImage) => [...oldImage, reader.result]);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const CreateCustomTicketform = async (e) => {
    e.preventDefault();
     console.log({bannerData})
    // Validation based on booking type
    if (bannerData?.bookingType == "juniper") {
      if (!activityText) {
        toast.error("Please Select Code");
        return; // Exit function if validation fails
      }
    } else if (bannerData?.bookingType == "elevate") {
      if (!bannerData?.cancellationPolicy) {
        toast.error("Enter Cancellation Policy");
        return; // Exit function if validation fails
      }
    }

    let payload = {
      customTicketId: id,
      title: bannerData.title,
      description: bannerData.description,
      badge: bannerData.badge,
      city: bannerData.city,
      images: url,
      categoryIds: categoryDataIds,
      subCategoryIds: subCategoryDataIds,
      code: bannerData?.code || state?.heliDubai?.tourProductId || state?.rayna?.tourId || state?.bigbus?.id || state?.dubaiPark?.productId || state?.emaar?.ProductId,
      bookingType: bannerData?.bookingType,
      startingAmount: bannerData?.startingAmount,
      isFreeCancellation: bannerData?.isFreeCancellation,
      cancellationPolicy: bannerData?.cancellationPolicy,
      ticketOptions: selectedTicketoption,
      features: packages,
      whatsInclude: WhatsInclude,
    };
    setShowLoder(true);
    const response = await postRequest("rayna/custom/update", payload);
    console.log({ response });
    if (response.status == 1) {
      toast.success(response.message);
      setShowLoder(true);
      setTimeout(() => {
        history.push("/custom/tickets");
      }, 1000);
    } else {
      setShowLoder(false);
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    const response = await postRequestForm("comman/doc/upload", formData);
    let image = response.data.url;
    seturl((oldImage) => [...oldImage, image]);
  };

  const RemoveImage = (index) => {
    console.log({ url });
    let image = previewImage.filter((i, j) => j !== index);
    let UrlImage = url.filter((i, j) => j !== index);
    setPreviewImage(image);
    seturl(UrlImage);
  };

  useEffect(() => {
    Imageupload();
  }, [selectedImage]);

  // moda code ticket
  const [activityModel, setActivityModel] = useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState();
  const [activityImage, setActivityImage] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [updateTicketpData, setUpdateTicketpData] = useState(null);

  const toggleActivityModel = () => {
    console.log("toggleActivityModel", activityModel);
    setActivityModel(!activityModel);
  };

  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const selectActivityRow = (id, name, activity) => {
    setActivityText(id?.name);
    setActivityImage(activity?.image);
    console.log("selected data", id);
    const formInput = { ...bannerData, code: id?.code };

    console.log({ formInput });
    setBannerData(formInput);
    closeActivityModal();
  };


  // Heli Dubai Ticket Modal code

  const [heliDubaiModel, setHeliDubaiModel] = useState(false);

  const [heliDubaiId, setHeliDubaiId] = useState();
  const [heliDubaiText, setHeliDubaiText] = useState(state?.heliDubai?.title);
  const [heliDubaiImage, setHeliDubaiImage] = useState("");
  const [selectedHeliIndex, setSelectedHeliIndex] = useState(-1);
  const [updateHeliTicketpData, setUpdateHeliTicketpData] = useState(null);

  const toggleHeliModel = () => {
    console.log("toggleHeliModel", heliDubaiModel);
    setHeliDubaiModel(!heliDubaiModel);
  };

  const closeHeliModel = () => {
    setHeliDubaiModel(false);
  };

  const selectHeliDubaiRow = (id, title, activity) => {
    setHeliDubaiText(id?.title);
    setHeliDubaiImage(activity?.image);
    console.log("selected data", id);
    const formInput = { ...bannerData, code: id?.tourProductId };
    console.log({ formInput });
    setBannerData(formInput);
    closeHeliModel();
  };


// Emaar Ticket Modal code

const [emaarModel, setEmaarModel] = useState(false);

const [emaarId, setEmaarId] = useState("");
const [emaarText, setEmaarText] = useState(state?.emaar?.ProductName);
const [emaarImage, setEmaarImage] = useState(state?.emaar?.ProductBanner);
const [selectedEmaarIndex, setSelectedEmaarIndex] = useState(-1);
const [updateEmaarTicketpData, setUpdateEmaarTicketpData] = useState(null);
console.log("emaar", emaarText);

const toggleEmaarModel = () => {
  console.log("toggleEmaarModel", emaarModel);
  setEmaarModel(!emaarModel);
};

const closeEmaarModel = () => {
  setEmaarModel(false);
};

const selectEmaarRow = (id, title, activity,ProductName,ProductBanner) => {

  console.log("selected data", id);
  setEmaarText(id?.ProductName);
  setEmaarImage(id?.ProductBanner);
  console.log("selected data", id);
  const formInput = { ...bannerData, code: id?.ProductId };

  console.log({ formInput });
  setBannerData(formInput);
  closeEmaarModel();
};

    // Dubai Park Ticket Modal code

const [dubaiParkModel, setDubaiParkModel] = useState(false);

const [dubaiParkId, setDubaiParkId] = useState("");
const [dubaiParkText, setDubaiParkText] = useState(state?.duabiPark?.productName);
const [dubaiParkImage, setDubaiParkImage] = useState("");
const [selectedDubaiParkIndex, setSelectedDubaiParkIndex] = useState(-1);
const [updateDubaiParkTicketpData, setUpdateHDubaiParkTicketpData] = useState(null);
console.log("dubaiParkText", dubaiParkText);

const toggleDubaiParkModel = () => {
  console.log("toggleDubaiParkModel", dubaiParkModel);
  setDubaiParkModel(!dubaiParkModel);
};

const closeDubaiParkModel = () => {
  setDubaiParkModel(false);
};

const selectDubaiParkRow = (id, title, activity) => {

  console.log("selected data", id);
  setDubaiParkText(id?.name);
  setDubaiParkImage(id?.imageUrl);
  console.log("selected data", id);
  const formInput = { ...bannerData, code: id?.productId };

  console.log({ formInput });
  setBannerData(formInput);
  closeDubaiParkModel();
};


  // Bigbus Ticket Modal code

  const [bigbusModel, setBigbusModel] = useState(false);
  const [bigbusId, setBigbusId] = useState();
  const [bigbusText, setBigbusText] = useState(state?.bigBus?.title);
  const [bigbusImage, setBigbusImage] = useState("");
  const [selectedBigbusIndex, setSelectedBigbusIndex] = useState(-1);
  const [updateBigbusTicketpData, setUpdateBigbusTicketpData] = useState(null);

  const toggleBigbusModel = () => {
    console.log("toggleBigbusModel", bigbusModel);
    setBigbusModel(!bigbusModel);
  };

  const closeBigbusModel = () => {
    setBigbusModel(false);
  };

  const selectBigbusRow = (id, title, activity) => {
    setBigbusText(id?.title);
    setBigbusImage(activity?.image);
    console.log("selected data", id);
    const formInput = { ...bannerData, code: id?.id };

    console.log({ formInput });
    setBannerData(formInput);
    closeBigbusModel();
  };


   // Rayna Ticket Modal code

   const [raynaModel, setRaynaModel] = useState(false);
   const [raynaId, setRaynaId] = useState("");
   const [raynaText, setRaynaText] = useState(state?.rayna?.tourName);
   const [raynaImage, setRaynaImage] = useState("");
   const [selectedRaynaIndex, setSelectedRaynaIndex] = useState(-1);
   const [updateRaynaTicketpData, setUpdateRaynaTicketpData] = useState(null);
 
   const toggleRaynaModel = () => {
     console.log("toggleRaynaModel", raynaModel);
     setRaynaModel(!raynaModel);
   };
 
   const closeRaynaModel = () => {
     setRaynaModel(false);
   };
 
   const selectRaynaRow = (id, title, activity) => {
     setRaynaText(id?.tourName);
     setRaynaImage(activity?.image);
     console.log("selected data", id);
     const formInput = { ...bannerData, code: id?.tourId };
 
     console.log({ formInput });
     setBannerData(formInput);
     closeRaynaModel();
   };


  // ticket option modal code

  const [TicketModalshow, setTicketModalshow] = useState(false);

  const showTicketModel = () => {
    setUpdateTicketpData({});
    setTicketModalshow(true);
  };

  const closeTicketModal = () => {
    setTicketModalshow(false);
  };

  // useEffect(() => {
  //   SelectformData();
  // }, [selectedTicketoption]);

  const SelectformData = (data) => {
    let customtickets = [...selectedTicketoption];
    if (selectedIndex !== -1) {
      customtickets[selectedIndex] = data;
      setselectedTicketoption(customtickets);
      setUpdateTicketpData({});
      setSelectedIndex(-1);
    } else {
      setselectedTicketoption([...selectedTicketoption, data]);
    }
  };

  const handleRemoveTicket = (index) => {
    const newArray = [...selectedTicketoption];
    newArray.splice(index);
    setselectedTicketoption(newArray);
    console.log(selectedTicketoption);
  };

  const handleUpdateTicket = (data, index) => {
    setTicketModalshow(true);
    setSelectedIndex(index);
    setUpdateTicketpData(data);
  };
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Ticket Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ticket Management</li>
              <li className="breadcrumb-item">Update Custom Ticket </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Update Custom Ticket</h4>

            
                <Form onSubmit={CreateCustomTicketform}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={bannerData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <ReactQuill
                              style={{
                                height: "100px",
                                width: "100%",
                                marginBottom: "60px",
                              }}
                              value={bannerData?.description || ""}
                              onChange={(value) =>
                                updateValue("description", value)
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Badge
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("badge", e.target.value)
                              }
                              value={bannerData?.badge}
                              name="badge"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            City
                          </label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("city", e.target.value)
                              }
                              name="city"
                              value={bannerData?.city}
                              class="form-select"
                            >
                              <option value="">Select</option>

                              <option value="abu dhabi">Abu Dhabi</option>
                              <option value="ajman">Ajman</option>
                              <option value="dubai">Dubai</option>
                              <option value="fujairah">Fujairah</option>
                              <option value="sharjah">Sharjah</option>
                              <option value="ras al khaimah">
                                Ras Al Khaimah
                              </option>
                              <option value="umm al quwain">
                                Umm Al Quwain
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            <div className="row">
                              {previewImage.map((image, index) => (
                                <div className="d-flex align-items-center flex-column col-md-3">
                                  <img
                                    key={index} // Add a unique key for each image in the array
                                    src={previewImage[index]}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      padding: "5px",
                                    }}
                                    alt={`Preview ${index + 1}`} // Provide alt text for accessibility
                                  />

                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => RemoveImage(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      

                       

                        <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Code
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleRaynaModel()}
                            >
                              {raynaText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleRaynaModel()}
                                  >
                                    Choose Code
                                  </button>
                                  {/* <div
                                       style={{
                                         border: "1px solid #ced4da",
                                         width: "16px",
                                       }}
                                     ></div> */}
                                  {/* <img
                                       src={activityImage}
                                       style={{
                                         width: "40px",
                                         height: "40px",
                                         border: "1px solid #ced4da",
                                         borderRight: "none",
                                         borderLeft: "none",
                                       }}
                                     ></img> */}
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={raynaText}
                                    id="selectedVenue"
                                    placeholder="No Code chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleRaynaModel()}
                                  >
                                    Choose Code
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Code chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Cancellation Policy
                              </label>
                              <div className="col-sm-6">
                                <textarea
                                  rows="4"
                                  onChange={(e) =>
                                    updateValue(
                                      "cancellationPolicy",
                                      e.target.value
                                    )
                                  }
                                  value={bannerData?.cancellationPolicy}
                                  name="cancellationPolicy"
                                  className="form-control"
                                />
                              </div>
                            </div>
                         
                        


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature List
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages?.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.feature}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            What's Include
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {WhatsInclude?.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModalInclude(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModalInclude(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.feature}`}
                                        onChange={(e) => {
                                          updatePackageFieldInclude(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() =>
                                            saveIncludePackage(index)
                                          }
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() =>
                                            deleteIncludePackage(index)
                                          }
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addIncludeRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Starting Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("startingAmount", e.target.value)
                              }
                              value={bannerData?.startingAmount}
                              name="startingAmount"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Free Cancellation Available
                          </label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue(
                                  "isFreeCancellation",
                                  e.target.value
                                )
                              }
                              value={bannerData?.isFreeCancellation}
                              name="isFreeCancellation"
                              class="form-select"
                            >
                              <option value="">Select</option>

                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
             
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <TicketModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      /> */}

      {/* <HeliDubaiTicketModal
      show={heliDubaiModel}
      closeModal={closeHeliModel}
      selectRow={selectHeliDubaiRow}      
      /> */}
       <RaynaTicketModal
      show={raynaModel}
      closeModal={closeRaynaModel}
      selectRow={selectRaynaRow}      
    />
       {/* <BigbusTicketModal
      show={bigbusModel}
      closeModal={closeBigbusModel}
      selectRow={selectBigbusRow}     
    /> */}
{/* 
    <DubaiParkTicketModal
      show={dubaiParkModel}
      closeModal={closeDubaiParkModel}
      selectRow={selectDubaiParkRow}      
    /> */}
     {/* <EmaarTicketModal
      show={emaarModel}
      closeModal={closeEmaarModel}
      selectRow={selectEmaarRow}      
    /> */}


      {/* <CustomTicketModal
        updateTicketpData={updateTicketpData}
        selectedTicketoption={selectedTicketoption}
        selectedIndex={selectedIndex}
        show={TicketModalshow}
        closeModal={closeTicketModal}
        SelectformData={SelectformData}
      /> */}

      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
        selectIncludeRow={selectIncludeIconRow}
      />

      <IconPackModalupdated
        show={iconModalInclude}
        closeModal={closeIconModalInclude}
        selectRow={selectIconRowIWhatsnclude}
        selectIncludeRow={selectIncludeIconRowWhats}
      />
    </React.Fragment>
  );
}
