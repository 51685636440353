import React from "react";
import { Redirect } from "react-router-dom";
//Email
// Authentication related pages
import Login from "../pages/Authentication/Login";
import EventLogin from "../pages/Authentication/EventLogin";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserManagmentlist from "pages/UserManagement/UserManagmentlist";
import Updateuserdata from "pages/UserManagement/Updateuserdata";
import Userdetails from "pages/UserManagement/Userdetails.js";
import RoleAccessManagement from "pages/RoleAccessManagement/UserManagmentlist";
import CreateRoleAccess from "pages/RoleAccessManagement/CreateUser";
import UpdateRoleAccess from "pages/RoleAccessManagement/Updateuserdata";
import EventForgotPassword from "pages/Authentication/EventForgotPassword";
import AdminForgotPassword from "pages/Authentication/AdminForgotPassword";

import BusinessManagmentlist from "pages/BusinessManagement/BusinessManagmentlist";
import BusinessDeletelist from "pages/BusinessManagement/BusinessDeletelist";
import CreateBusiness from "pages/BusinessManagement/CreateBusiness";
import UpdateBusiness from "pages/BusinessManagement/UpdateBusiness";
import VenueManagmentlist from "pages/VenueManagement/VenueManagmentlist";
import VenueOutingManagementList from "pages/VenueManagement/VenueOutingManagementList";
import VenueDeletelist from "pages/VenueManagement/VenueDeletelist";
import CreateVenue from "pages/VenueManagement/CreateVenue";
import UpdateVenue from "pages/VenueManagement/UpdateVenue";
import MenuManagmentlist from "pages/MenuManagement/MenuManagmentlist";
import CreateMenu from "pages/MenuManagement/CreateMenu";
import UpdateMenu from "pages/MenuManagement/UpdateMenu";
import RatingManagmentlist from "pages/RatingManagement/RatingManagmentlist";
import FollowManagementlist from "pages/FollowManagement/FollowManagmentlist";
import UpdateRating from "pages/RatingManagement/UpdateRating";
import ThemeManagmentlist from "pages/ThemeManagement/ThemeManagmentlist";
import CreateTheme from "pages/ThemeManagement/CreateTheme";
import UpdateTheme from "pages/ThemeManagement/UpdateTheme";
import CuisineManagmentlist from "pages/CuisineManagement/CuisineManagmentlist";
import CreateCuisine from "pages/CuisineManagement/CreateCuisine";
import UpdateCuisine from "pages/CuisineManagement/UpdateCuisine";
import CreateBusinessTheme from "pages/BusinessManagement/CreateBusinessTheme";
import CreateVenueTheme from "pages/VenueManagement/CreateVenueTheme";
import CreateVenueCuisine from "pages/VenueManagement/CreateVenueCuisine";
import CreateStory from "pages/StoryManagement/CreateStory";
import StoryManagmentlist from "pages/StoryManagement/StoryManagmentlist";
import PageManagementlist from "pages/AppManagement/PageManagementlist";
import CreatePage from "pages/AppManagement/CreatePage";
import UpdatePage from "pages/AppManagement/UpdatePage";
import VenueStoryManagementlist from "pages/VenueManagement/VenueStoryManagmentlist";
import CreateVenueStory from "pages/VenueManagement/CreateVenueStory";
import GalleryManagementlist from "pages/GalleryManagement/GalleryManagmentlist";
import CreateGallery from "pages/GalleryManagement/CreateGallery";
import CreateVenueMusic from "pages/VenueManagement/CreateVenueMusic";
import UpdateCredential from "pages/VenueManagement/UpdateCredential";
import CreateVenueFeature from "pages/VenueManagement/CreateVenueFeature";
import CustomComponentManagementlist from "pages/CustomComponentManagement/CustomCompoManagmentlist";
import CreateCustomComponent from "pages/CustomComponentManagement/CreateCustomComponent";
import InviteListTable from "pages/EventManagement/InviteListTable";
import CreateVenueAssignMusic from "pages/VenueManagement/CreateVenueAssignMusic";
import CreateVenueAssignFeature from "pages/VenueManagement/CreateVenueAssignFeature";
import CreateVenueAssignTheme from "pages/VenueManagement/CreateVenueAssignTheme";
import CreateVenueAssignCuisine from "pages/VenueManagement/CreateVenueAssignCuisine";
import VideoManagementlist from "pages/VideoManagement/VideoManagmentlist";
import CreateVideo from "pages/VideoManagement/CreateVideo";
import UpdateVideo from "pages/VideoManagement/UpdateVideo";
import HomeBlockManagementlist from "pages/HomeBlockManagement/HomeBlockManagmentlist";
import CreateHomeBlock from "pages/HomeBlockManagement/CreateHomeBlock";
import UpdateHomeBlock from "pages/HomeBlockManagement/UpdateHomeBlock";
import SearchBlockManagementlist from "pages/SearchBlockManagement/HomeBlockManagmentlist";
import CreateSearchBlock from "pages/SearchBlockManagement/CreateHomeBlock";
import UpdateSearchBlock from "pages/SearchBlockManagement/UpdateHomeBlock";
import OfferManagementlist from "pages/OfferManagement/OfferManagmentlist";
import OfferDeletelist from "pages/OfferManagement/OfferDeletelist";
import OfferNotificationlist from "pages/OfferManagement/OfferNotificationlist";
import CreateOffer from "pages/OfferManagement/CreateOffer";
import UpdateOffer from "pages/OfferManagement/UpdateOffer";
import PlusoneUserManagementlist from "pages/PlusOneUserManagement/PlusoneUserManagmentlist";
import RaynaTicketManagement from "pages/RaynaTicket/RaynaTicketManagement";
import CustomTicketManagementList from "pages/CustomTicket/CustomTicketManagementList";
import CreateCustomTicket from "pages/CustomTicket/CreateCustomTicket";
import UpdatedCustomTicket from "pages/CustomTicket/UpdatedCustomTicket";
import PublicHoliday from "pages/OfferManagement/PublicHolidayManagmentlist";
import CreatePublicHoliday from "pages/OfferManagement/CreateHoliday";
import UpdatePublicHoliday from "pages/OfferManagement/UpdateHoliday";

import TimeManagementlist from "pages/TimeManagement/TimeManagmentlist";
import CreateTime from "pages/TimeManagement/CreateTime";
import UpdateTime from "pages/TimeManagement/UpdateTime";

import DiscountTypeManagementlist from "pages/DiscountManagement/DiscountTypeManagmentlist";
import CreateDiscountType from "pages/DiscountManagement/CreateDiscountType";
import UpdateDiscountType from "pages/DiscountManagement/UpdateDiscountType";
import DiscountManagementlist from "pages/DiscountManagement/DiscountManagmentlist";
import CreateDiscount from "pages/DiscountManagement/CreateDiscount";
import UpdateDiscount from "pages/DiscountManagement/UpdateDiscount";
import BannerTypeManagementlist from "pages/BannerManagement/BannerTypeManagmentlist";
import CreateBannerType from "pages/BannerManagement/CreateBannerType";
import UpdateBannerType from "pages/BannerManagement/UpdateBannerType";
import BannerManagementlist from "pages/BannerManagement/BannerManagmentlist";
import CreateBanner from "pages/BannerManagement/CreateBanner";
import UpdateBanner from "pages/BannerManagement/UpdateBanner";
import AddReply from "pages/RatingManagement/AddReply";
import SpecialOfferManagementlist from "pages/OfferManagement/SpecialOfferManagmentlist";
import SpecialOfferDeletelist from "pages/OfferManagement/SpecialOfferDeletelist";
import SpecialOfferClaimManagementlist from "pages/OfferManagement/SpecialOfferClaimManagmentlist";
import ActivityBannerManagementlist from "pages/ActivityBannerManagement/ActivityBannerManagmentlist";
import ActivityFollowManagementlist from "pages/ActivityFollowManagement/ActivityFollowManagmentlist";
import UpdateActivityRating from "pages/ActivityRatingManagement/UpdateActivityRating";
import CreateActivityBanner from "pages/ActivityBannerManagement/CreateActivityBanner";

import MusicList from "pages/Music/MusicList";
import CreateMusic from "pages/Music/CreateMusic";
import UpdateMusic from "pages/Music/UpdateMusic";
import FeatureList from "pages/Features/FeatureList";
import CreateFeature from "pages/Features/CreateFeature";
import UpdateFeature from "pages/Features/UpdateFeature";
import CheckinList from "pages/VenueCheckinManagement/CheckinList";
import BlockSizeList from "pages/HomeBlockSize/BlockSizeList";
import CreateBlockSize from "pages/HomeBlockSize/CreateBlockSize";
import UpdateBlockSize from "pages/HomeBlockSize/UpdateBlockSize";
import CategoryList from "pages/CategoryManagement/CategoryList";
import CreateCategory from "pages/CategoryManagement/CreateCategory";
import UpdateCategory from "pages/CategoryManagement/UpdateCategory";
import Categorydetails from "pages/CategoryManagement/Categorydetails";
import ChartComponets from "pages/DashboardChart/ChartComponent";
import SpecialOfferlist from "pages/SpecialOffer/SpecialOffer";
import CreateSpecialOffer from "pages/SpecialOffer/CreateSpecialOffer";
import UpdateSpecialOffer from "pages/SpecialOffer/UpdateSpecialOffer";
import CreateClaim from "pages/OfferManagement/CreateClaim";
import Homeblockdetails from "pages/HomeBlockManagement/Homeblockdetails";
import homeBlockPreview from "pages/HomeBlockManagement/HomeBlockPreview";
import Searchblockdetails from "pages/SearchBlockManagement/Homeblockdetails";
import searchBlockPreview from "pages/SearchBlockManagement/HomeBlockPreview";

import PackageList from "pages/PackageManagement/PackageList";
import CreatePackage from "pages/PackageManagement/CreatePackage";
import UpdatePackage from "pages/PackageManagement/UpdatePackage";

import PromocodeList from "pages/PromocodeManagement/PromocodeList";
import CreatePromocode from "pages/PromocodeManagement/CreatePromocode";
import UpdatePromocode from "pages/PromocodeManagement/UpdatePromocode";
import LogManagementListRole from "pages/RoleAccessManagement/LogManagementList";

import DealList from "pages/DealManagement/DealList";

import OrderList from "pages/OrderManagement/OrderManagmentlist";
import ActivityOrderManagementlist from "pages/ActivityOrderManagement/ActivityOrderManagmentlist";
import OfferOrderManagementlist from "pages/OfferOrderManagement/OfferOrderManagmentlist";
import OfferReportManagementlist from "pages/OfferOrderManagement/OfferReportManagmentlist";

import DealOrderManagementlist from "pages/DealOrderManagement/DealOrderManagmentlist";
import RaynaBookingList from "pages/BookingManagement/RaynaBookingList";
import RaynaBookingDetailPage from "pages/BookingManagement/RaynaBookingDetailPage";
import Venuedetails from "pages/VenueManagement/Venuedetails";
import VenueOutingdetails from "pages/VenueManagement/VenueOutingdetails";
import NotificationList from "pages/Notification/NotificationList";
import UpdateNotification from "pages/Notification/UpdateNotification";
import CreateNotification from "pages/Notification/CreateNotification";
import Notificationdetails from "pages/Notification/NotificationDetails";
import UpdateCustomComponent from "pages/CustomComponentManagement/UpdateCustomComponent";
import YachtClublist from "pages/YachtClub/YachtClubList";
import CreateYachtClub from "pages/YachtClub/CreateYachtClub";
import UpdateYachtClub from "pages/YachtClub/UpdateYachtClub";
import YachtClubdetails from "pages/YachtClub/Yachtclubdetails";
import YachtManagementlist from "pages/YachtManagement/YachtManagementList";
import CreateYacht from "pages/YachtManagement/CreateYacht";
import UpdateYacht from "pages/YachtManagement/UpdateYacht";
import YachtGalleryManagementlist from "pages/YachtGallery/YachtGalleryManagement";
import CreateYachtGallery from "pages/YachtGallery/CreateYachtGallery";
import YachtRatingManagementlist from "pages/YachtRating/YachtRatingManagementList";
import UpdateYachtRating from "pages/YachtRating/UpdateYachtRating";

import AddYachtRatingReply from "pages/YachtRating/AddYachtRatingReply";
import YachtFollowManagementlist from "pages/yachtFollow/YachtFollowManagmentlist";

import EventOrganizerlist from "pages/EventOrganizer/EventOrganizerlist";
import CreateEventOrganizerData from "pages/EventOrganizer/CreateEventOrganizer";
import UpdateEventOrganizerData from "pages/EventOrganizer/UpdateEventOrganizer";
import Eventdetails from "pages/EventManagement/Eventdetails";
import Organizerdetails from "pages/EventOrganizer/Organizerdetails";
import EventFollowManagementlist from "pages/EventFollowManagement/EventFollowManagmentlist";
import EventOrderManagmentlist from "pages/EventOrderManagement/EventOrderManagmentlist";

import Eventlist from "pages/EventManagement/Eventlist";
import EventDeletelist from "pages/EventManagement/EventDeletelist";
import CreateEvent from "pages/EventManagement/CreateEvent";
import UpdateEvent from "pages/EventManagement/UpdateEvent";
import ActivityProviderlist from "pages/ActivityProvider/ActivityProviderlist";
import CreateActivityProvider from "pages/ActivityProvider/CreateActivityProvider";
import UpdateActivityProvider from "pages/ActivityProvider/UpdateActivityProvider";

import EventGalleryManagementlist from "pages/EventGalleryManagement/EventGalleryManagementlist";
import CreateEventGallery from "pages/EventGalleryManagement/CreateEventGallery";

import EventRatingManagementlist from "pages/EventRatingManagement/EventRatingManagementList";
import AddEventRatingReply from "pages/EventRatingManagement/AddEventRatingReply";
import UpdateEventRating from "pages/EventRatingManagement/UpdateEventRating";
import EventOrganizerdetails from "pages/EventOrganizerDetail/EventOrganizerdetails";
import UpdateOrganizerProfile from "pages/EventOrganizerDetail/UpdateOrganizerProfile";
import BucketList from "pages/UserManagement/BucketList";
import BucketManagementList from "pages/BucketManagement/BucketManagementList";
import OutingManagementList from "pages/OutingManagement/OutingManagementList";
import ActivityTypeList from "pages/ActivityType/ActivityTypeList";
import CreateActivityType from "pages/ActivityType/ActivityTypeCreate";
import UpdateActivityType from "pages/ActivityType/ActivityTypeUpdate";
import ActivityManagementlist from "pages/ActivityManagement/ActivityManagementList";
import ActivityDeletelist from "pages/ActivityManagement/ActivityDeleteList";
import IconList from "pages/IconManagement/IconList";
import CreateIcon from "pages/IconManagement/CreateIcon";
import ActivityRatingManagementlist from "pages/ActivityRatingManagement/ActivityRatingManagementList";
import CreateActivity from "pages/ActivityManagement/createActivity";
import Activitydetails from "pages/ActivityManagement/Activitydetails";
import UpdateActivity from "pages/ActivityManagement/UpdateActivity";
import SubscriptionManagementlist from "pages/SubscriptionManagement/SubsriptionManagmentlist";
import CustomSubscriptionManagementlist from "pages/CustomSubscriptionManagement/CustomSubsriptionManagmentlist";
import CreateCustomSubscribe from "pages/CustomSubscriptionManagement/CreateCustomSubscribe";
import UpdateCustomSubscribe from "pages/CustomSubscriptionManagement/UpdateCustomSubscribe";
import CreateUser from "pages/UserManagement/CreateUser";
import CloneNotification from "pages/Notification/CloneNotification";
import NotificationReadManagementlist from "pages/NotificationRead/NotificationReadManagmentlist";
import UpdateEventOrg from "pages/EventOrganizerDetail/eventOrgProfile";
import AdsManagementlist from "pages/ADsManagements/AdManagementList";
import AdminProfileUpdate from "pages/AdminManagement/adminProfile";
import AdminSetting from "pages/AdminManagement/adminSetting";

import CreateAd from "pages/ADsManagements/CreateAd";

import UpdateAd from "pages/ADsManagements/UpdateAd";
import CloneAd from "pages/ADsManagements/CloneAd";
import Adlogslist from "pages/ADsManagements/Adlogslist";
import AdGraphdetails from "pages/ADsManagements/AdGraphdetails";
import NotificationForm from "pages/In-appNotification/InAppNotificationCreate";
import BusinessLogin from "pages/Authentication/BusinessLogin";
import BusinessDetails from "pages/BusinessPortal/BusinessDetails";
import BusinessForgotPassword from "pages/Authentication/BusinessForgotPassword";
import ResetBusinessPassword from "pages/BusinessManagement/ResetPassword";
import ContactUsList from "pages/ContactUs/ContactUsList";
import ConversationList from "pages/Conversation/ConversationList"; 
import AddMessage from "pages/ContactUs/ContactUsReply";
import ContactUsMessages from "pages/ContactUs/ContactUsMessages";
import ConversationMessages from "pages/Conversation/ContactUsMessages";
import VoucherList from "pages/VoucherManagement/VoucherManagementList";
import CreateVoucher from "pages/VoucherManagement/CreateVoucher";
import UpdateVoucher from "pages/VoucherManagement/UpdateVoucher";
import InAppNotificationlist from "pages/In-appNotification/InAppNotificationList";
import Graph from "pages/Dashboard/graph";
import VenueGraphdetails from "pages/VenueManagement/VenueGraphdetails";
import EventGraphdetails from "pages/EventManagement/EventGraphdetails";
import OfferGraphdetails from "pages/OfferManagement/OfferGraphdetails";
import Businessdetail from "pages/BusinessManagement/Businessdetail";
import DeleteAccountManagementlist from "pages/UserManagement/DeleteAccountManagmentlist";

import BADashboard from "../pages/BusinessP/Dashboard/index";
import BABusinessManagementlist from "pages/BusinessP/BusinessManagement/BusinessManagmentlist";
import BAUpdateBusiness from "pages/BusinessP/BusinessManagement/UpdateBusiness";
import BAResetBusinessPassword from "pages/BusinessManagement/ResetPassword";
import BABusinessdetails from "pages/BusinessP/BusinessManagement/Businessdetail";

import BAVenueManagmentlist from "pages/BusinessP/VenueManagement/VenueManagmentlist";
import BACreateVenue from "pages/BusinessP/VenueManagement/CreateVenue";
import BAUpdateVenue from "pages/BusinessP/VenueManagement/UpdateVenue";
import BAUpdateCredential from "pages/BusinessP/VenueManagement/UpdateCredential";
import BAVenuedetails from "pages/BusinessP/VenueManagement/Venuedetails";
import BAVenueOutingdetails from "pages/BusinessP/VenueManagement/VenueOutingdetails";
import BAGalleryManagementlist from "pages/BusinessP/GalleryManagement/GalleryManagmentlist";
import BACreateGallery from "pages/BusinessP/GalleryManagement/CreateGallery";
import BAMusicList from "pages/BusinessP/Music/MusicList";
import BACreateMusic from "pages/BusinessP/Music/CreateMusic";
import BAUpdateMusic from "pages/BusinessP/Music/UpdateMusic";
import BAFeatureList from "pages/BusinessP/Features/FeatureList";
import BACreateFeature from "pages/BusinessP/Features/CreateFeature";
import BAUpdateFeature from "pages/BusinessP/Features/UpdateFeature";
import BAThemeManagmentlist from "pages/BusinessP/ThemeManagement/ThemeManagmentlist";
import BACreateTheme from "pages/BusinessP/ThemeManagement/CreateTheme";
import BAUpdateTheme from "pages/BusinessP/ThemeManagement/UpdateTheme";
import BACuisineManagmentlist from "pages/BusinessP/CuisineManagement/CuisineManagmentlist";
import BACreateCuisine from "pages/BusinessP/CuisineManagement/CreateCuisine";
import BAUpdateCuisine from "pages/BusinessP/CuisineManagement/UpdateCuisine";
import BARatingManagmentlist from "pages/BusinessP/RatingManagement/RatingManagmentlist";
import BAUpdateRating from "pages/BusinessP/RatingManagement/UpdateRating";
import BAAddReply from "pages/BusinessP/RatingManagement/AddReply";
import BAFollowManagementlist from "pages/BusinessP/FollowManagement/FollowManagmentlist";
import BAVoucherList from "pages/BusinessP/VoucherManagement/VoucherManagementList";
import BACreateVoucher from "pages/BusinessP/VoucherManagement/CreateVoucher";
import BAUpdateVoucher from "pages/BusinessP/VoucherManagement/UpdateVoucher";
import BAPublicHoliday from "pages/BusinessP/OfferManagement/PublicHolidayManagmentlist";
import BACreatePublicHoliday from "pages/BusinessP/OfferManagement/CreateHoliday";
import BAUpdatePublicHoliday from "pages/BusinessP/OfferManagement/UpdateHoliday";
import BASpecialOfferClaimManagementlist from "pages/BusinessP/OfferManagement/SpecialOfferClaimManagmentlist";
import BAOfferOrderManagementlist from "pages/BusinessP/OfferOrderManagement/OfferOrderManagmentlist";
import BAOfferNotificationlist from "pages/BusinessP/OfferManagement/OfferNotificationlist";
import BAOfferManagementlist from "pages/BusinessP/OfferManagement/OfferManagmentlist";
import BACreateOffer from "pages/BusinessP/OfferManagement/CreateOffer";
import BAUpdateOffer from "pages/BusinessP/OfferManagement/UpdateOffer";
import BASpecialOfferManagementlist from "pages/BusinessP/OfferManagement/SpecialOfferManagmentlist";
import BACreateSpecialOffer from "pages/BusinessP/SpecialOffer/CreateSpecialOffer";
import BAUpdateSpecialOffer from "pages/BusinessP/SpecialOffer/UpdateSpecialOffer";
import BACategoryList from "pages/BusinessP/CategoryManagement/CategoryList";
import BACreateCategory from "pages/BusinessP/CategoryManagement/CreateCategory";
import BAUpdateCategory from "pages/BusinessP/CategoryManagement/UpdateCategory";
import BACategorydetails from "pages/BusinessP/CategoryManagement/Categorydetails";
import BABannerManagementlist from "pages/BusinessP/BannerManagement/BannerManagmentlist";
import BACreateBanner from "pages/BusinessP/BannerManagement/CreateBanner";
import BAUpdateBanner from "pages/BusinessP/BannerManagement/UpdateBanner";
import BAEventlist from "pages/BusinessP/EventManagement/Eventlist";
import BACreateEvent from "pages/BusinessP/EventManagement/CreateEvent";
import BAUpdateEvent from "pages/BusinessP/EventManagement/UpdateEvent";
import BAEventdetails from "pages/BusinessP/EventManagement/Eventdetails";
import BAInviteListTable from "pages/BusinessP/EventManagement/InviteListTable";
import YachtPackageManagementlist from "pages/YachPackagesManagement/YachtPackageManagementlist";
import AddPackagesYach from "pages/YachPackagesManagement/AddPackagesYach";
import YachticonManagementList from "pages/YachtIconManagement/YachticonManagementList";
import AddOnCreatedYacht from "pages/YachtIconManagement/AddOnCreatedYacht";
import UpdatedAddonYacht from "pages/YachtIconManagement/UpdatedAddonYacht";
import UpdateYachPackages from "pages/YachPackagesManagement/UpdateYachPackages";
import LogManagementList from "../pages/LogManagementList/LogManagementList";
import HomeBlockLogManagment from "../pages/HomeBlockManagement/HomeBlockLogManagment";
import YachtOfferList from "pages/YachtOfferManagementList/YachtOfferList";
import CreateYachtOffer from "pages/YachtOfferManagementList/CreateYachtOffer";
import YachtOfferPackageUpdate from "pages/YachtOfferManagementList/YachtOfferPackageUpdate";
import PromoterManagementList from "pages/PromoterManagement/PromoterManagementList";
import RingManagementList from "pages/RingManagement/RingManagementList";
import UserPromoterEventDetails from "pages/UserManagement/UserPromoterEventDetails";

import BACreateStory from "pages/BusinessP/StoryManagement/CreateStory";
// import BAUpdateStory from "pages/BusinessP/StoryManagement/UpdateStory";
import BAStoryManagmentlist from "pages/BusinessP/StoryManagement/StoryManagmentlist";


const userRoutes = [
  // Dashboard
  { path: "/dashboard", component: Dashboard },
  // Chart List
  { path: "/chart", component: ChartComponets },
  // Graph page
  { path: "/graph", component: Graph },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  { path: "/users", component: UserManagmentlist },

  // Delete Account List
  { path: "/deleteAccount", component: DeleteAccountManagementlist },

  // Plusone Account List
  { path: "/plusone-user", component: PlusoneUserManagementlist },
  // business List
  { path: "/business", component: BusinessManagmentlist },

  // Business Account Dashboard
  { path: "/business-account/dashboard", component: BADashboard },

  // Business Account Business List
  { path: "/business-account/business", component: BABusinessManagementlist },

  // admin profile
  { path: "/adminProfile", component: AdminProfileUpdate },

  // admin profile Setting
  { path: "/adminSetting", component: AdminSetting },

  // business delete List
  { path: "/businessDelete", component: BusinessDeletelist },
  // create business
  { path: "/createBusiness", component: CreateBusiness },
  // update business
  { path: "/updateBusiness/:id", component: UpdateBusiness },
  // Update Business Account
  { path: "/business-account/updateBusiness/:id", component: BAUpdateBusiness },
  // reset business password
  { path: "/resetBusinessPassword/:id", component: ResetBusinessPassword },

  // Reset Business Account Password
  {
    path: "/business-account/resetBusinessPassword/:id",
    component: BAResetBusinessPassword,
  },

  // Business Details
  { path: "/businessdetails/:id", component: Businessdetail },
  // Business Account Business Details
  {
    path: "/business-account/businessdetails/:id",
    component: BABusinessdetails,
  },
  // Venue List
  { path: "/venues", component: VenueManagmentlist },
  // Business Account Venue List
  { path: "/business-account/venues", component: BAVenueManagmentlist },

  { path: "/updateCredential/:id", component: UpdateCredential },
  // Update Business Account Credential
  {
    path: "/business-account/updateCredential/:id",
    component: BAUpdateCredential,
  },
  // Venue Outing List
  { path: "/venueOuting/:id", component: VenueOutingManagementList },
  // Venue delete List
  { path: "/venueDelete", component: VenueDeletelist },
  // Create Venue
  { path: "/createVenue", component: CreateVenue },
  // Business Account Create Venue
  { path: "/business-account/createVenue", component: BACreateVenue },
  { path: "/createVenueTheme", component: CreateVenueTheme },
  { path: "/createVenueCuisine", component: CreateVenueCuisine },
  { path: "/createVenueMusic", component: CreateVenueMusic },
  { path: "/createVenueFeature", component: CreateVenueFeature },
  { path: "/createVenueAssignMusic", component: CreateVenueAssignMusic },
  { path: "/createVenueAssignFeature", component: CreateVenueAssignFeature },
  { path: "/createVenueAssignTheme", component: CreateVenueAssignTheme },
  { path: "/createVenueAssignCuisine", component: CreateVenueAssignCuisine },

  { path: "/venuedetails/:id", component: Venuedetails },
  // Business Account Venue Details
  { path: "/business-account/venuedetails/:id", component: BAVenuedetails },
  // Venue Outing Details
  { path: "/venueOutingdetails/:id", component: VenueOutingdetails },

  // Business Account Venue Outing Details
  {
    path: "/business-account/venueOutingdetails/:id",
    component: BAVenueOutingdetails,
  },

  { path: "/bucketdetails/:id", component: BucketList },

  // Update Venue
  { path: "/updateVenue/:id", component: UpdateVenue },

  // Business Account Update Venue
  { path: "/business-account/updateVenue/:id", component: BAUpdateVenue },

  // Rayna 
  { path:"/rayna-tickets",component:RaynaTicketManagement},

  // custom ticket 
{ path: "/custom/tickets", component: CustomTicketManagementList },
{ path: "/custom/tickets/create", component: CreateCustomTicket },
{ path: "/custom/tickets/update/:id", component: UpdatedCustomTicket },


  // Venue Graph
  { path: "/venueGraphdetails/:id", component: VenueGraphdetails },
  // Menu List
  { path: "/menu/:id", component: MenuManagmentlist },
  // Create Menu
  { path: "/createMenu/:id", component: CreateMenu },
  // Story List
  { path: "/story/:id", component: VenueStoryManagementlist },
  { path: "/createStory/:id", component: CreateVenueStory },
  // Update Menu
  { path: "/updateMenu/:id", component: UpdateMenu },
  // Rating List
  { path: "/ratings", component: RatingManagmentlist },
  // Business Account Rating List
  { path: "/business-account/ratings", component: BARatingManagmentlist },
  // Update Rating
  { path: "/updateRating/:id", component: UpdateRating },
  // Business Account Update Rating
  { path: "/business-account/updateRating/:id", component: BAUpdateRating },
  //add reply
  { path: "/addReply/:id", component: AddReply },
  // Business Account Add Reply
  { path: "/business-account/addReply/:id", component: BAAddReply },
  // Theme List
  { path: "/themes", component: ThemeManagmentlist },
  // Business Account Theme List
  { path: "/business-account/themes", component: BAThemeManagmentlist },

  { path: "/createBusinessTheme/:id", component: CreateBusinessTheme },

  // Create Theme
  { path: "/createTheme", component: CreateTheme },
  // Business Account Create Theme
  { path: "/business-account/createTheme", component: BACreateTheme },
  // Update Theme
  { path: "/updateTheme/:id", component: UpdateTheme },
  // Business Account Update Theme
  { path: "/business-account/updateTheme/:id", component: BAUpdateTheme },

  // Cuisine List
  { path: "/cuisines", component: CuisineManagmentlist },
  // Business Account Cuisine List
  { path: "/business-account/cuisines", component: BACuisineManagmentlist },
  // Create Cuisine
  { path: "/createCuisine", component: CreateCuisine },
  // Business Account Create Cuisine
  { path: "/business-account/createCuisine", component: BACreateCuisine },
  // Update Cuisine
  { path: "/updateCuisine/:id", component: UpdateCuisine },
  // Business Account Update Cuisine
  { path: "/business-account/updateCuisine/:id", component: BAUpdateCuisine },

  { path: "/updateuserdata/:id", component: Updateuserdata },
  { path: "/userdetails/:id", component: Userdetails },
  { path: "/usercreate", component: CreateUser },

  //Story List
  { path: "/stories", component: StoryManagmentlist },
  
  { path: "/business-account/stories", component: BAStoryManagmentlist },

  //home block logs
  { path: "/home-block/logs", component: HomeBlockLogManagment },

  // Story
  { path: "/createStory", component: CreateStory },

  { path: "/business-account/createStory", component: BACreateStory },

  //Page List
  { path: "/pages", component: PageManagementlist },
  //Create Page
  { path: "/createPage", component: CreatePage },
  //Update Page
  { path: "/updatePage/:id", component: UpdatePage },
  //Gallery List
  { path: "/galleries", component: GalleryManagementlist },
  // Business Account Gallery List
  { path: "/business-account/galleries", component: BAGalleryManagementlist },
  //Create Gallery
  { path: "/createGallery", component: CreateGallery },
  // Business Account Create Gallery
  { path: "/business-account/createGallery", component: BACreateGallery },
  // videos List
  { path: "/videos", component: VideoManagementlist },
  // Create Video
  { path: "/createVideo", component: CreateVideo },
  // Update Video
  { path: "/updateVideo/:id", component: UpdateVideo },

  // Time List
  { path: "/time/:id", component: TimeManagementlist },
  // Create Time
  { path: "/createTime/:id", component: CreateTime },
  // Update Time
  { path: "/updateTime/:id", component: UpdateTime },

  // HomeBlock List
  { path: "/homeblock", component: HomeBlockManagementlist },
  // create HomeBlock
  { path: "/createHomeblock", component: CreateHomeBlock },
  // Update HomeBlock
  { path: "/updateHomeblock/:id", component: UpdateHomeBlock },
  //Home Block Details
  { path: "/homeblockdetails/:id", component: Homeblockdetails },

  //Home Block Preview
  { path: "/homeBlockPreview", component: homeBlockPreview },

  // SearchBlock List
  { path: "/searchblock", component: SearchBlockManagementlist },
  // create SearchBlock
  { path: "/createSearchblock", component: CreateSearchBlock },
  // Update SearchBlock
  { path: "/updateSearchblock/:id", component: UpdateSearchBlock },
  //Search Block Details
  { path: "/searchblockdetails/:id", component: Searchblockdetails },

  //Search Block Preview
  { path: "/searchBlockPreview", component: searchBlockPreview },

  //Music List
  { path: "/music", component: MusicList },
  // Business Account Music List
  { path: "/business-account/music", component: BAMusicList },
  { path: "/Createmusicdata", component: CreateMusic },
  // Business Account Create Music
  { path: "/business-account/Createmusicdata", component: BACreateMusic },
  { path: "/Updatemusicdata/:id", component: UpdateMusic },
  // Business Account Update Music
  { path: "/business-account/Updatemusicdata/:id", component: BAUpdateMusic },

  // Booking List
  { path:"/rayna-bookings",component:RaynaBookingList},
  { path:"/rayna-booking-details/:id",component:RaynaBookingDetailPage},
  // Feature List
  { path: "/feature", component: FeatureList },
  // Business Account Feature List
  { path: "/business-account/feature", component: BAFeatureList },
  { path: "/Createfeaturedata", component: CreateFeature },
  // Business Account Create Feature
  { path: "/business-account/Createfeaturedata", component: BACreateFeature },

  { path: "/Updatefeaturedata/:id", component: UpdateFeature },
  // Business Account Update Feature
  {
    path: "/business-account/Updatefeaturedata/:id",
    component: BAUpdateFeature,
  },

  // Venue Checkin List
  { path: "/venuecheckin", component: CheckinList },

  // BlockSize List
  { path: "/blocksize", component: BlockSizeList },
  { path: "/Createsizedata", component: CreateBlockSize },
  { path: "/Updatesizedata/:id", component: UpdateBlockSize },

  // Icon List
  { path: "/activityIcon", component: IconList },
  // Create Icon
  { path: "/createIcon", component: CreateIcon },

  // Activity Rating List
  { path: "/activityRating", component: ActivityRatingManagementlist },
  // update Activity Rating
  { path: "/updateActivityRating/:id", component: UpdateActivityRating },

  //Discount Type List
  { path: "/discountType", component: DiscountTypeManagementlist },
  // Create Discount Type
  { path: "/createDiscountType", component: CreateDiscountType },
  // Update Discount Type
  { path: "/updateDiscountType/:id", component: UpdateDiscountType },

  //Discount List
  { path: "/discount", component: DiscountManagementlist },
  // Create Discount
  { path: "/createDiscount", component: CreateDiscount },
  // Update Discount
  { path: "/updateDiscount/:id", component: UpdateDiscount },

  //Banner Type List
  { path: "/bannerType", component: BannerTypeManagementlist },
  // Create Banner Type
  { path: "/createBannerType", component: CreateBannerType },
  // Update Banner Type
  { path: "/updateBannerType/:id", component: UpdateBannerType },

  //Banner List
  { path: "/banner", component: BannerManagementlist },

  // Business Account Banner List
  { path: "/business-account/banner", component: BABannerManagementlist },
  // Create Banner
  { path: "/createBanner", component: CreateBanner },
  // Business Account Create Banner
  { path: "/business-account/createBanner", component: BACreateBanner },
  // Update Banner
  { path: "/updateBanner/:id", component: UpdateBanner },
  // Business Account Update Banner
  { path: "/business-account/updateBanner/:id", component: BAUpdateBanner },
  //Activity Banner List
  { path: "/activityBanner", component: ActivityBannerManagementlist },
  // Create Activity Banner
  { path: "/createActivityBanner", component: CreateActivityBanner },

  // create Activity
  { path: "/createActivity", component: CreateActivity },
  // Update Activity
  { path: "/updateActivity/:id", component: UpdateActivity },
  // Activity Details
  { path: "/activitydetails/:id", component: Activitydetails },

  // Subscriber List
  { path: "/subscribe", component: SubscriptionManagementlist },

  // Custom Subscription List
  { path: "/customsubscribe", component: CustomSubscriptionManagementlist },
  { path: "/createCustomSubscribe", component: CreateCustomSubscribe },
  { path: "/updateCustomSubscribe/:id", component: UpdateCustomSubscribe },

  //Offer List
  { path: "/offer", component: OfferManagementlist },

  // Business Account Offer List
  { path: "/business-account/offer", component: BAOfferManagementlist },

  // Offer Delete List
  { path: "/offerDelete", component: OfferDeletelist },

  //Public Holiday List
  { path: "/public-holiday", component: PublicHoliday },
  // Business Account Public Holiday List
  { path: "/business-account/public-holiday", component: BAPublicHoliday },
  // Create holiday
  { path: "/createHoliday", component: CreatePublicHoliday },
  // Business Account Create holiday
  { path: "/business-account/createHoliday", component: BACreatePublicHoliday },
  // Update holiday
  { path: "/updateHoliday/:id", component: UpdatePublicHoliday },
  // Business Account Update holiday
  {
    path: "/business-account/updateHoliday/:id",
    component: BAUpdatePublicHoliday,
  },

  // Offer graph
  { path: "/offerGraphdetails/:id", component: OfferGraphdetails },
  // Offer Notification List
  { path: "/offerNotification", component: OfferNotificationlist },
  // Business Account Offer Notification List
  {
    path: "/business-account/offerNotification",
    component: BAOfferNotificationlist,
  },
  // Create Offer
  { path: "/createOffer", component: CreateOffer },
  // Business Account Create Offer
  { path: "/business-account/createOffer", component: BACreateOffer },
  // Update Offer
  { path: "/updateOffer/:id", component: UpdateOffer },
  // Business Account Update Offer
  { path: "/business-account/updateOffer/:id", component: BAUpdateOffer },
  // Category List
  { path: "/category", component: CategoryList },

  // promotor list 
  { path: "/promoter", component: PromoterManagementList },
  { path: "/ring", component: RingManagementList },

  // Business Account Category List
  { path: "/business-account/category", component: BACategoryList },
  { path: "/Createcategorydata", component: CreateCategory },
  // Business Account Create Category
  { path: "/business-account/Createcategorydata", component: BACreateCategory },
  { path: "/Updatecategorydata/:id", component: UpdateCategory },
  // Business Account Update Category
  {
    path: "/business-account/Updatecategorydata/:id",
    component: BAUpdateCategory,
  },
  { path: "/categorydetails/:id", component: Categorydetails },
  // Business Account Category Details
  {
    path: "/business-account/categorydetails/:id",
    component: BACategorydetails,
  },

  // Role Access Management

  { path: "/roles", component: RoleAccessManagement },
  { path: "/createRole", component: CreateRoleAccess },
  { path: "/updateRole/:id", component: UpdateRoleAccess },

  // Notification
  { path: "/notification", component: NotificationList },
  { path: "/notificationdetails/:id", component: Notificationdetails },
  { path: "/notificationclone", component: CloneNotification },
  { path: "/Createnotification", component: CreateNotification },
  { path: "/Updatenotificationdata/:id", component: UpdateNotification },
  { path: "/notificationread", component: NotificationReadManagementlist },

  // Event Organizer List
  { path: "/eventOrganizer", component: EventOrganizerlist },
  { path: "/createeventorg", component: CreateEventOrganizerData },
  { path: "/updateeventorg/:id", component: UpdateEventOrganizerData },
  { path: "/organizerdetails/:id", component: Organizerdetails },
  { path: "/eventdetails/:id", component: Eventdetails },
  { path: "/User-Promoter-EventDetails/:id", component: UserPromoterEventDetails },

  // Business Account Event Details
  { path: "/business-account/eventdetails/:id", component: BAEventdetails },

  //

  //Event Organizer Details
  { path: "/eventOrganizerDetails", component: EventOrganizerdetails },
  { path: "/updateOrganizerProfile", component: UpdateOrganizerProfile },
  { path: "/updateOrgProfile", component: UpdateEventOrg },

  //Business Portal
  { path: "/businessDetails", component: BusinessDetails },
  { path: "/updateOrganizerProfile", component: UpdateOrganizerProfile },
  { path: "/updateOrgProfile", component: UpdateEventOrg },

  //

  // Event Organizer Gallery List
  { path: "/eventGallery", component: EventGalleryManagementlist },
  { path: "/createEventGallery", component: CreateEventGallery },

  // Event Organizer Rating List
  { path: "/eventRating", component: EventRatingManagementlist },

  // Business
  { path: "/updateeventrating/:id", component: UpdateEventRating },
  { path: "/addeventratingreply/:id", component: AddEventRatingReply },

  // Evet Organizer Follow List
  { path: "/eventFollow", component: EventFollowManagementlist },
  { path: "/eventOrders", component: EventOrderManagmentlist },

  // Event List
  { path: "/events", component: Eventlist },
  // Business Account Event List
  { path: "/business-account/events", component: BAEventlist },
  { path: "/createevents", component: CreateEvent },
  // Business Account Create Event
  { path: "/business-account/createevents", component: BACreateEvent },
  { path: "/updateevents/:id", component: UpdateEvent },
  // Business Account Update Event
  { path: "/business-account/updateevents/:id", component: BAUpdateEvent },

  { path: "/inviteList/:id", component: InviteListTable },
  // Business Account Invite List
  { path: "/business-account/inviteList/:id", component: BAInviteListTable },

  // Event Delete List
  { path: "/eventDelete", component: EventDeletelist },

  // Event graph
  { path: "/eventGraphdetails/:id", component: EventGraphdetails },

  // YachtClub
  { path: "/yachtclub", component: YachtClublist },
  { path: "/notificationdetails/:id", component: Notificationdetails },
  { path: "/Createyachtclub", component: CreateYachtClub },
  { path: "/Updateyachtclub/:id", component: UpdateYachtClub },

  { path: "/yachtclubdetails/:id", component: YachtClubdetails },

  // Yacht Management
  { path: "/yacht", component: YachtManagementlist },
  { path: "/notificationdetails/:id", component: Notificationdetails },
  { path: "/Createyacht", component: CreateYacht },
  { path: "/Updateyacht/:id", component: UpdateYacht },

  // Yacht Gallery Management
  { path: "/yachtgallery", component: YachtGalleryManagementlist },
  { path: "/Createyachtgallery", component: CreateYachtGallery },

  // Yacht Rating List
  { path: "/yachtrating", component: YachtRatingManagementlist },
  { path: "/updateyachtrating/:id", component: UpdateYachtRating },
  { path: "/addyachtratingreply/:id", component: AddYachtRatingReply },

  // log management list
  { path: "/Log-Management-list", component: LogManagementList },
   // Log Management detail in Role Access
   { path: "/log-management-list/:id", component: LogManagementListRole, permission: "adminManagement"},

  // yacht Offer
  { path: "/yacht-offer-list", component: YachtOfferList },
  { path: "/yacht-offer-create", component: CreateYachtOffer },
  { path: "/yacht-offer-update/:_id", component: YachtOfferPackageUpdate },
  // yacht packages
  {
    path: "/Yacht-Package-Management-list",
    component: YachtPackageManagementlist,
  },
  { path: "/Yacht-Package-Create", component: AddPackagesYach },
  { path: "/Yacht-Package-Update/:_id", component: UpdateYachPackages },

  // yacht icon management

  { path: "/Yacht-icon-Management-list", component: YachticonManagementList },
  { path: "/Create-Add-On", component: AddOnCreatedYacht },
  { path: "/update-Add-On/:_id", component: UpdatedAddonYacht },

  // Yacht Follow List
  { path: "/yachtfollow", component: YachtFollowManagementlist },

  // Bucket Management List
  { path: "/bucketlist", component: BucketManagementList },
  { path: "/updateyachtrating/:id", component: UpdateYachtRating },
  { path: "/addyachtratingreply/:id", component: AddYachtRatingReply },

  // Outing Management List
  { path: "/outinglist", component: OutingManagementList },

  // Activity Management List
  { path: "/activityProvider", component: ActivityProviderlist },
  { path: "/createActivityProvider", component: CreateActivityProvider },
  { path: "/updateActivityProvider/:id", component: UpdateActivityProvider },

  // Activity Delete List
  { path: "/activityDelete", component: ActivityDeletelist },

  // Activity Type Management List
  { path: "/activityType", component: ActivityTypeList },
  { path: "/Createactivitytypedata", component: CreateActivityType },
  { path: "/Updateactivitytypedata/:id", component: UpdateActivityType },

  // Activity Management List
  { path: "/activityManagement", component: ActivityManagementlist },
  { path: "/Createactivitytypedata", component: CreateActivityType },
  { path: "/Updateactivitytypedata/:id", component: UpdateActivityType },

  // Activity Follow List
  { path: "/activityFollow", component: ActivityFollowManagementlist },

  // in-app notification
  { path: "/inAppNotification", component: InAppNotificationlist },
  { path: "/inAppNotificationCreate", component: NotificationForm },

  // Contact Us
  { path: "/contactUs", component: ContactUsList },
  { path: "/addMessage/:id", component: AddMessage },
  { path: "/messages", component: ContactUsMessages },

  // Conversation List

  { path: "/conversation", component: ConversationList },
  { path: "/addMessage/:id", component: AddMessage },
  { path: "/promoter-messages", component: ConversationMessages },




  // Special Offer List
  //{ path: "/specialOffer", component: SpecialOfferManagementlist },

  // Speccial Discount Delete List
  { path: "/specialOfferDelete", component: SpecialOfferDeletelist },

  // Special Offer Claim List
  { path: "/SpecialOfferClaim", component: SpecialOfferClaimManagementlist },
  //Business Account Special Offer Claim List
  {
    path: "/business-account/SpecialOfferClaim",
    component: BASpecialOfferClaimManagementlist,
  },
  // Special Offer List
  { path: "/specialoffer", component: SpecialOfferManagementlist },
  // Business Account Special Offer List
  {
    path: "/business-account/specialoffer",
    component: BASpecialOfferManagementlist,
  },
  // { path: "/specialoffer", component: SpecialOfferlist },
  { path: "/CreateSpecialOffer", component: CreateSpecialOffer },
  // Business Account Create Special Offer
  {
    path: "/business-account/CreateSpecialOffer",
    component: BACreateSpecialOffer,
  },

  { path: "/UpdateSpecialOffer/:id", component: UpdateSpecialOffer },
  // Business Account Update Special Offer
  {
    path: "/business-account/UpdateSpecialOffer/:id",
    component: BAUpdateSpecialOffer,
  },
  // Create Claim
  { path: "/createClaim", component: CreateClaim },
  // package List
  { path: "/package", component: PackageList },
  // Create package
  { path: "/Createpackagedata", component: CreatePackage },
  // Update package
  { path: "/Updatepackagedata/:id", component: UpdatePackage },

  // promocode List
  { path: "/promocode", component: PromocodeList },
  // Create promocode
  { path: "/Createpromocode", component: CreatePromocode },
  // Update promocode
  { path: "/Updatepromocode/:id", component: UpdatePromocode },

  // // Deal List
  // { path: "/deals", component: DealList },
  // Voucher List
  { path: "/vouchers", component: VoucherList },
  // Business Account Voucher List
  { path: "/business-account/vouchers", component: BAVoucherList },
  { path: "/createvoucher", component: CreateVoucher },
  // Business Account Create Voucher
  { path: "/business-account/createvoucher", component: BACreateVoucher },
  { path: "/updateVoucher/:id", component: UpdateVoucher },
  // Business Account Update Voucher
  { path: "/business-account/updatevoucher/:id", component: BAUpdateVoucher },

  { path: "/customComponents", component: CustomComponentManagementlist },
  // Create Custom Component
  { path: "/createCustomComponent", component: CreateCustomComponent },
  // Update Custom Component
  { path: "/updateCustomComponent/:id", component: UpdateCustomComponent },
  // Follow List
  { path: "/follows", component: FollowManagementlist },

  // Business Account Follow List
  { path: "/business-account/follows", component: BAFollowManagementlist },
  // Ads List
  { path: "/AdsList", component: AdsManagementlist },
  // Create Ads
  { path: "/createAd", component: CreateAd },
  //  Update Ads
  { path: "/updateAd/:id", component: UpdateAd },
  // Clone Ads
  { path: "/cloneAd", component: CloneAd },
  // Ad Logs List
  { path: "/adLogs", component: Adlogslist },
  // Ad Graph
  { path: "/adGraph/:id", component: AdGraphdetails },

  { path: "/orders", component: OrderList },

  { path: "/activityOrder", component: ActivityOrderManagementlist },

  { path: "/offerOrder", component: OfferOrderManagementlist },

  { path: "/offerSalesReport", component: OfferReportManagementlist },

  // Business Account Offer Order List
  {
    path: "/business-account/offerOrder",
    component: BAOfferOrderManagementlist,
  },

  { path: "/dealOrder", component: DealOrderManagementlist },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/business-account/login", component: Login },
  { path: "/forgotPassword", component: AdminForgotPassword },
  { path: "/eventlogin", component: EventLogin },
  { path: "/eventForgotPassword", component: EventForgotPassword },
  { path: "/businesslogin", component: BusinessLogin },
  { path: "/businessForgotPassword", component: BusinessForgotPassword },
];

export { userRoutes, authRoutes };
