import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
// import rButton from "reactstrap/button";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
  deleteRequest,
  putRequest,
  postRequest,
  putRequestForm,
  deleteConfirmationWords,
} from "components/Common/Utils";
import { useParams, useLocation } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Lightbox from "react-image-lightbox";


import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// import "./CustomCard.css";
// import "./Custom.css";
// import RoomListTable from "./RoomTable";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

const defaultPosition = {
  lat: 33.6706,
  lng: 36.0227,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const RaynaBookingDetailPage = () => {
  const location = useLocation();
  const state = location.state?.row;
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [venueData, setData] = useState(state);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div >
          <button type="button" className=" btn btn-primary text-light">
            {" "}
            <Link
              to={{
                pathname: `/bookingLinedetails`,
                state: { row, id },
              }}
            >
              {" "}
              <i className="fal fa-eye text-light"></i>
            </Link>{" "}
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  function lineAtdata(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{moment(row?.LineDate).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }
  function NoOfMembers(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{row?.passengers?.length} </p>
        </div>
      </React.Fragment>
    );
  }
 

  function BookingDate(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{moment(row?.TourDetails[0]?.tourDate).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }

  function BookingTime(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{row?.TourDetails[0]?.startTime} </p>
        </div>
      </React.Fragment>
    );
  }

  function ticketFormater(cellContent, row) {
    return (
      <div>
       
        <p>{row?.tourData?.tourName || ""}</p>
      </div>
    );
  }

  function imageFormater(cellContent, row) {
    return (
      <div >
        <img
          src={row?.tourData?.imagePath || ""}
          alt="Image"
          width={"50px"}
          style={{ borderRadius: "20%" }} // This makes the image round
        />
       
      </div>
    );
  }
  function optionFormater(cellContent, row) {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <img
          src={row?.ticketOption?.images?.[0] || ""}
          alt="Image"
          width={"50px"}
          style={{ borderRadius: "20%" }} // This makes the image round
        />
        <p>{row?.ticketOption?.title || ""}</p>
      </div>
    );
  }
  const columns = [
    {
      dataField: "service",     
      formatter: ticketFormater,
      text: "Service Name",
      sort: false,
    },
    {
      dataField: "Option",
      formatter: imageFormater,
      text: "Service Image",
      sort: false,
    },
    {
      dataField: "BeginTravelDate",
      text: "Total Passengers",
      formatter: NoOfMembers,
      sort: false,
    },
    {
      dataField: "tourDate",
      formatter: BookingDate,
      text: "Booking date",
      sort: false,
    },
    {
      dataField: "time",
      text: "Booking Time",
      formatter: BookingTime,
      sort: false,
    },
  ];




  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#3f51b5", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "75px",
      height: "75px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },

    gridList: {
      width: "100%",
    },
    icon: {
      color: "rgba(255, 55, 25, 0.54)",
    },

    // galleryImage: {
    //   width: "100%",
    // },
  });

  const breakpointColumnsObj = {
    default: 3, // adjust number of columns here
    1100: 3,
    700: 2,
    500: 1,
  };

  const classes = useStyles();

  const { id } = useParams();

  console.log("--->>>", { id })



  const VenueFetchDetail = async () => {

    // setLoader(false);
    postRequest(`rayna/booking-detail`, { bookingId: `${id}` }).then((data) => {
      let venueData = data?.data || {};
      let line = venueData?.Lines || [];
      console.log("test--api--hit", venueData)
      //let status = data.status;
      setData(venueData);
      setLine(line);

      const policyData = fetchPolicyData(venueData);
      const priceData = venueData?.type == "transfer" ? venueData?.transfers?.map(transfer => transfer?.price) : venueData?.type == "hotel" ? venueData?.hotels?.map(hotel => hotel.price) : venueData?.tickets?.map(ticket => ticket.price);
      const infoData = fetchInfoData(venueData);

      setPolicyTab(policyData);
      setPriceTab(priceData);
      setInfoTab(infoData);


      console.log("venueData");
      console.log(venueData);

      setLoader(false);
    });
  };
  useEffect(() => {
    VenueFetchDetail();
  }, []);

  const fetchPolicyData = (venueData) => {
    if (venueData?.type == "transfer") {
      const policyData = venueData?.transfers?.map((item) => {
        const plObj = {
          description: item?.cancellationPolicy.Description,
          name: item?.transferInfo.Name,
        }
        return plObj;
      });
      return policyData;
    }

    if (venueData?.type == "hotel") {
      const policyData = venueData?.hotels?.map((item) => {
        const plObj = {
          description: item?.cancellationPolicy.Description,
          name: item?.hotelInfo?.Name,
        }
        return plObj;
      });
      return policyData;
    }

    if (venueData?.type == "ticket") {
      const policyData = venueData?.tickets?.map((item) => {
        const plObj = {
          description: item?.cancellationPolicy?.Description,
          name: item?.ticketInfo?.Name,
        }
        return plObj;
      });
      return policyData;
    }
  }

  const fetchInfoData = (venueData) => {
    if (venueData?.type == "transfer") {
      const policyData = venueData?.transfers?.map((item) => {
        const plObj = {
          description: item?.transferInfo?.Description,
          name: item?.transferInfo?.Name,
          image: item?.transferInfo.Images?.length > 0 ? item?.transferInfo.Images[0].Image : "",
          code: item?.transferDetails?.Code,
          start: item?.transferDetails?.Start,
          end: item?.transferDetails?.End,
          status: item?.transferDetails?.Status,
        }
        return plObj;
      });
      return policyData;
    }

    if (venueData?.type == "hotel") {
      const policyData = venueData?.hotels?.map((item) => {
        const plObj = {
          name: item?.hotelInfo?.Name,
          hotelCategory: item?.hotelInfo?.HotelCategory,
          hotelType: item?.hotelInfo?.Type,
          address: item?.hotelInfo?.Address,
          code: item?.hotelInfo?.Code,
          start: item?.hotelDetails?.Start,
          end: item?.hotelDetails?.End,
          status: item?.hotelDetails?.Status,
        }
        return plObj;
      });
      return policyData;
    }

    if (venueData?.type == "ticket") {
      const policyData = venueData?.tickets?.map((item) => {
        const plObj = {
          description: item?.ticketInfo?.Description,
          name: item?.ticketInfo?.Name,
          image: item?.ticketInfo?.Images?.length > 0 ? item?.ticketInfo?.Images[0].Image : "",
          code: item?.ticketDetails?.Code,
          start: item?.ticketDetails?.Start,
          end: item?.ticketDetails?.End,
          status: item?.ticketDetails?.Status,
        }
        return plObj;
      });
      return policyData;
    }
  }

  const Updatevenue = async (e) => {
    const formData = new FormData();
    formData.append("venueId", id);

    if (timeOptions && timeOptions.length > 0) {
      timeOptions.forEach((item, index) => {
        if (item?.checked) {
          formData.append(`days[]`, days[index].toString().toLowerCase());
          formData.append(`open_time[]`, item?.open);
          formData.append(`close_time[]`, item?.close);
        }
      });
    }

    const response = await putRequestForm("venue/update", formData);

    if (response.status == 1) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          // remove from venueData
          const vData = { ...venueData };
          vData[key] = vData[key].filter((item) => item?._id !== id);
          setData(vData);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const handlerdelete2 = (id, file) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, file);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords?.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryFile(id, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryFile = async (id, file) => {
    const response = await deleteRequest(`venue/gallery/delete`, {
      venueId: id,
      file: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      TransferFetchDetail();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerToggle = async (url, obj) => {
    await deleteRequest(`${url}`, obj);
  };

  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });

    // update in venueData as well
    const selReview = selectedReview;

    if (selReview.reply?.reply) {
      selReview.reply.reply = textReply;
    } else {
      selReview.reply = { reply: textReply };
    }

    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const showLightBoxGallery = (imgSrc) => {

    const index = images.indexOf(imgSrc);
    setPhotoIndex(index !== -1 ? index : 0);
    setOpenLightBoxGallery(true);
  }


  const {

    holderName,
    locator,
    status,
    userData,
    type,
    comment,
    externalBookingReference,
    createdAt,
    bookingType
  } = venueData || {};

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Booking Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Booking Management</li>:{" "}
              <li className="breadcrumb-item">Booking details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              {/* <div
                  className="cover-image"
                  style={{ backgroundImage: `url(${coverImage})` }}
                  onClick={() => showLightBox(coverImage)}
                ></div> */}
              <div
                className="cover-image"
                style={{ backgroundColor: "rgb(29, 155, 240)" }}
              ></div>
              <div className="profile-image" style={{top: "150px"}}>
                <img
                  src={`https://ui-avatars.com/api/?background=random&name=${holderName}`}
                  onClick={() => showLightBox(`https://ui-avatars.com/api/?background=random&name=${holderName}`)}
                  alt="Image Not Available"
                />

              </div>
              <br></br>

              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "20px" }}
                >
                  <div className="fst">
                    {" "}
                    <h3> {holderName} </h3>{" "}
                  </div>
                  <div className="row">

                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Email: <span> {venueData?.passengers?.[0]?.email}</span>{" "}
                      </p>{" "}
                    </div>
                    {/* <div className="col-md-4 fst py-1">
                        {" "}
                        <p>
                          {" "}
                          Nationality: <span> {venueData?.Holder?.nationality}</span>{" "}
                        </p>{" "}
                      </div> */}


                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Phone: <span> {venueData?.passengers?.[0].mobile}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Booking Type:
                        <span> {bookingType} </span>
                      </p>
                    </div>

                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Booking Code:
                        <span> {venueData?.bookingCode} </span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Booking Status:
                        <span> {venueData?.bookingStatus} </span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Payment Status:
                        <span> {venueData?.paymentStatus} </span>
                      </p>
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      <p>
                        {" "}
                        Comment: <span> {venueData?.transferComments}</span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Col className="col-12">
              <Card>
                {loader ? (
                  <CardBody style={{ height: "100px" }}>
                    <Spinner
                      color="info"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        position: "absolute",
                        left: "50%",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </CardBody>
                ) : (
                  <CardBody className="table-shorting">
                    {
                      <PaginationProvider
                        pagination={paginationFactory({
                          page: currentFPage,
                          sizePerPage: sizePerPageOrg,
                          totalSize: totalPage,
                          custom: true,
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <>
                            <ToolkitProvider
                              keyField="_id"
                              data={[venueData]}
                              columns={columns}
                            >
                              {(props) => (
                                <Row>
                                  {/* <Col className="col-lg-12 d-flex mb-3">
                                    <label className="mb-0 text-label">
                                      Page Size
                                    </label>
                                    <select
                                      value={sizePerPageOrg}
                                      onChange={handleChange}
                                      className="form-select"
                                      style={{ width: "7%" }}
                                    >
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                    </select>
  
                                    <label className="mb-0 text-label ms-3">
                                      Sort By:
                                    </label>
                                    <select
                                      value={sort}
                                      onChange={handlesorting}
                                      className="form-select"
                                      style={{ width: "10%" }}
                                    >
                                     
                                      <option value="user">
                                        {" "}
                                        User
                                      </option>
                                      <option value="comment">Comment</option>
                                      <option value="externalBookingReference">Booking Reference</option>
                                      <option value="locator">Locator</option>
                                      <option value="type">Type</option>
                                      <option value="status">Status</option>
                                      <option value="createdAt">Created At</option>
                                    </select>
                                    <label className="mb-0 text-label ms-3">
                                      Sort Order:
                                    </label>
                                    <select
                                      value={sortOrder}
                                      onChange={handlesortOrder}
                                      className="form-select"
                                      style={{ width: "10%" }}
                                    >
                                      <option value="asc"> Ascending</option>
                                      <option value="desc">Descending</option>
                                      
                                    </select>
                                    <div className="filter-item">
                                      <input
                                        type="search"
                                        placeholder="Search"
                                        value={searchText}
                                        class="form-control"
                                        onChange={(e) =>
                                          setSearchText(e.target.value)
                                        }
                                        style={{ width: "30%" }}
                                      />
                                      <button
                                        onClick={() => {
                                          ratingManagment();
                                        }}
                                        type="button"
                                        className="btn btn-primary mx-2"
                                      >
                                        Search
                                      </button>
                                      <button
                                        type="button"
                                        onClick={handleReload}
                                        className="btn btn-danger mx-2"
                                      >
                                        Reset
                                      </button>
  
                                      <button
                                        type="button"
                                        onClick={toggleModel}
                                        className="btn btn-info"
                                      >
                                        Filter
                                      </button>
                                    </div>
                                  </Col> */}

                                  <Col className="col-lg-12 text-end">
                                    <BootstrapTable
                                      onTableChange={handleTableChange}
                                      remote={true}
                                      classes="table-striped"
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ToolkitProvider>

                            <Row>
                              <Col className="col-lg-12 text-end">
                                <div className="text-md-right">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </PaginationProvider>
                    }
                  </CardBody>
                )}
              </Card>
            </Col>
            {/* <Col md="12">
                <div
                  id="exTab2"
                  className="twitter-like-tabs2"
                  style={{ background: "#ffff", marginLeft: "25px" }}
                >
                  <ul
                    className="nav nav-pills"
                    style={{ width: "480px", display: "contents" }}
                  >
                    <li className="active">
                      <a href="#location" data-toggle="tab">
                        {" "}
                        Location{" "}
                      </a>
                    </li>
                 
                    <li className="active">
                      <a href="#members" data-toggle="tab">
                        {" "}
                        Members{" "}
                      </a>
                    </li>                
  
                    <li>
                      <a href="#offers" data-toggle="tab">
                        {" "}
                        Rooms
                      </a>
                    </li>                
  
                    <li>
                      <a href="#gallery" data-toggle="tab">
                        {" "}
                        Gallery{" "}
                      </a>
                    </li>                 
  
                    <li >
                      <a href="#price" data-toggle="tab">
                        {" "}
                        Price{" "}
                      </a>
                    </li>
                    <li >
                      <a href="#policy" data-toggle="tab">
                        {" "}
                        Policy{" "}
                      </a>
                    </li>
                    <li >
                      <a href="#bookinginfo" data-toggle="tab">
                        {" "}
                        Booking Info{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </Col> */}
            {/* <Col md="12">
                <div className="tab-content clearfix">               
               
  
                  <div className="tab-pane padded-tab" id="features">              
                    <div className="tab-pane padded-tab" id="cuisine">
                        <div className="card feature-card">
                            <div className="row">
                                {
                                    venueData?.features?.map((feature, index) => (
                                        <div key={index} className="col-md-3 fst py-1">
                                            <ul className="feature-list">
                                                <li className="feature-item">
                                                    {feature}
                                                </li>
                                            </ul>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
  
  
  
                <div className="tab-pane  padded-tab" id="price">
                 {venueData?._id  && (
                      <PriceTable
                        priceTab={priceTab}
                      />
                    )}
                  </div>    
                  <div className="tab-pane  padded-tab" id="policy">
                 {venueData?._id  && (
                      <PolicyTable
                      policyTab={policyTab}
                      />
                    )}
                  </div>    
  
                  <div className="tab-pane  padded-tab" id="bookinginfo">
                 {venueData?._id  && (
                      <BookingInfoTable
                        infoTab={infoTab} 
                        type={venueData?.type}                   
                      />
                    )}
                  </div>    
  
                  <div className="tab-pane active padded-tab" id="members">
                 {venueData?._id  && (
                      <MemberTable
                         members={venueData?.members}
                      />
                    )}
                  </div>                 
                 
                </div>
              </Col> */}
          </>
        )}

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}




      </Row>
      <ToastContainer />{" "}
    </>
  );
}
export default RaynaBookingDetailPage